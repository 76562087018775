import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import getWindowDimensions, { device } from '../../common/styles/device';
// See https://www.freecodecamp.org/news/how-to-keep-your-footer-where-it-belongs-59c6aa05c59c/

// import twitterIcon from '../../assets/images/twitterIcon.svg';
import twitterIcon from '../../assets/images/twitterIcon.svg';

const Footer = () => {
  const { isLarge } = getWindowDimensions();

  return (
    <StyledFooter>
      <LinkIcons>
        <Link to={{ pathname: 'https://twitter.com/ActOpInc' }} target='_blank'>
          <TwitterIcon src={twitterIcon} />
        </Link>
      </LinkIcons>
      <FooterLinks>
        <Copyright>©2021 ActOp, Inc.</Copyright>
        {isLarge && <Separator />}
        <StyledLink to='/legal/privacy-policy'>Privacy Policy</StyledLink>
        {isLarge && <Separator />}
        <StyledLink to='/legal/terms-of-service'>Terms of Service</StyledLink>
      </FooterLinks>
    </StyledFooter>
  );
};

export default Footer;

const LinkIcons = styled.div``;
const FooterLinks = styled.div`
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  @media ${device.large} {
    flex-direction: row;
    align-items: center;
  }
`;

const StyledFooter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${(props) => props.theme.colors.navbar};
  padding: 2rem 0;
`;

const TwitterIcon = styled.img`
  width: 1rem;
  fill: yellow;
  &:hover {
    cursor: pointer;
  }
`;

const Copyright = styled.div`
  margin: 0.3rem 0;
`;

const StyledLink = styled(Link)`
  color: white;
  margin: 0.3rem 0;
`;

const Separator = styled.div`
  margin: 0 0.5rem;
  &:before {
    content: '|';
  }
  @media ${device.large} {
    display: block;
  }
`;
