import React from 'react';
import { ThemeProvider } from 'styled-components';

const theme = {
  navbarHeight: '8rem',
  colors: {
    // primary: '#04024d',
    primary: '#04024d',
    subtle: 'ghostwhite',
    highlight: '#363299',
    background: 'white',
    logoText: 'black',
    logoHover: '#606060',
    menu: 'white',
    menuActive: 'black',
    navbar: '#1ca3c4',
  },
  fonts: ['Roboto'],
  fontSource: '',
  fontFamily: 'Poppins, sans-serif',
  fontSizes: {
    small: '1em',
    medium: '2em',
    large: '3em',
  },
};

const Theme = ({ children }) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
);

export default Theme;
