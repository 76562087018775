import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const NotFound = () => {
  return (
    <StyledNotFound>
      <Heading>Error: Page Not Found</Heading>
      <LinkTo to='/'>Go Home</LinkTo>
    </StyledNotFound>
  );
};

export default NotFound;

const StyledNotFound = styled.div`
  padding-top: 1rem;
  margin: 5rem;
`;
const Heading = styled.h1``;
const LinkTo = styled(Link)`
  color: blue;
  &:hover {
    text-decoration: underline;
  }
`;
