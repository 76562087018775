import sudokuSkyImg from '../../assets/images/sudoku_sky.png';
import sudokuSkyIcon from '../../assets/images/sudoky_sky_icon.png';
import heistFlightImg from '../../assets/images/heist_flight.png';
import heistFlightIcon from '../../assets/images/heist_flight_icon.png';
import brewtoonTycoonImg from '../../assets/images/brewtoon_tycoon.png';
import brewtoonTycoonIcon from '../../assets/images/brewtoon_tycoon_icon.png';
import bounceOfLegendsImg from '../../assets/images/bounce_of_legends.png';
import bounceOfLegendsIcon from '../../assets/images/bounce_of_legends_icon.png';
import doubleChipsImg from '../../assets/images/double_chips.png';
import doubleChipsIcon from '../../assets/images/double_chips_icon.png';
import werewolfRunImg from '../../assets/images/werewolf_run.png';
import werewolfRunIcon from '../../assets/images/werewolf_run_icon.png';
import croquetClashImg from '../../assets/images/croquet_clash.png';
import croquetClashIcon from '../../assets/images/croquet_clash_icon.png';
import miningMountainImg from '../../assets/images/mining_mountain.png';
import miningMountainIcon from '../../assets/images/mining_mountain_icon.png';
import nojoImg from '../../assets/images/nojo.png';
import nojoIcon from '../../assets/images/nojo_icon.png';
import metrigonImg from '../../assets/images/metrigon.png';
import metrigonIcon from '../../assets/images/metrigon_icon.png';

const gameList = [
  {
    title: 'Sudoku Sky',
    videoLink: '',
    icon: sudokuSkyIcon,
    image: { url: sudokuSkyImg, orientation: 'portrait' },
    description: [
      "Relax and challenge your mind with the best Sudoku game in the app store. This is Sudoku like you've never seen it before. Levels transition gently from day to night. Beautiful graphics set the tone to lower stress and help you unwind.",
    ],
    features: [
      'Simple mechanics',
      'Drag and drop numbers',
      'Great for beginners',
      'Helpful hints',
      'Highlight duplicates',
      '9x9 grid',
      'Intuitive design',
      'Play for free',
      '10,000+ levels',
    ],
    postDescription: ['Download this anxiety-reducing Sudoku game today!'],
    googlePlayLink:
      'https://play.google.com/store/apps/details?id=com.actop.sudoku',
    appStoreLink: 'https://apps.apple.com/us/app/sudoku-sky/id1450980532?ls=1',
  },
  {
    title: 'Heist Flight',
    videoLink: '',
    icon: heistFlightIcon,
    image: { url: heistFlightImg, orientation: 'portrait' },
    description: [
      'You robbed a bank of all their riches, but now you have to get away! Don’t let the cops catch you.',
      'Cross busy streets. Use power-ups. Don’t get hit by pedestrian vehicles. Dodge construction. Collect money. How far can you get? Escape the police!',
    ],
    features: [
      'Unlock a gun, shield, and more',
      'Buy new cars',
      'Earn achievements',
      'Progressive difficulty',
      'Hundreds of levels',
      'Avoid other cars, construction, cones, and dumpsters',
      'Intuitive design',
      'Play for free',
      'Collect money as you go',
    ],
    postDescription: [
      'Which vehicle is your favorite? Will you escape to live that rich life or spend your days in the slammer?',
    ],
    googlePlayLink:
      'https://play.google.com/store/apps/details?id=com.actop.getaway',
    appStoreLink:
      'https://itunes.apple.com/us/app/heist-flight/id1440288067?ls=1&mt=8',
  },
  {
    title: 'Brewtoon Tycoon',
    videoLink: '',
    icon: brewtoonTycoonIcon,
    image: { url: brewtoonTycoonImg, orientation: 'landscape' },
    description: [
      'Are you ready to become an expert craft brewer?',
      'You have come to the perfect place! Welcome to the number one brewery simulator.',
      "It's lager than life!",
    ],
    features: [
      'Fun, simple gameplay',
      'Brew all different kinds of beer',
      'Fill orders in the tasting room',
      'Make deliveries to venues around the world',
      'Inspired by rubberhose cartoons of the 1930s',
      'Learn interesting and exciting beer facts',
      'Grow your business from your basement all the way to a full-sized industrial brewery',
      'Design your own label',
      'Add special ingredients',
      'Upgrade your equipment',
      'Brew both ales and lagers',
      'Unlock many different ingredients',
      'Increase the worth of your beer',
      'Expand into all different kinds of bottles and containers',
      'Hire skilled employees who add all kinds of benefits',
      'Catch mischievous critters running amok in the brewery',
      'Enter exciting contests to win special prizes',
      'Sell your beer at special events and venues',
    ],
    postDescription: [],
    googlePlayLink:
      'https://play.google.com/store/apps/details?id=com.actop.brewtoon',
    appStoreLink:
      'https://itunes.apple.com/us/app/brewtoon-tycoon/id1377432237?ls=1&mt=8',
  },
  {
    title: 'Bounce of Legends',
    videoLink: 'https://youtu.be/SAqk_PP62Cw',
    icon: bounceOfLegendsIcon,
    image: { url: bounceOfLegendsImg, orientation: 'portrait' },
    description: ["Bam! Zoom! Zap! Don't drop the ball!"],
    features: [
      'Tap to keep the ball in the air',
      'Simple gameplay! Fun for everyone',
      'Shatter magic crystals to unlock super new balls and backgrounds',
      'Complete the challenges and earn EPIC prizes',
      'Play a brick breaker blitz. Be quick! It’s gone in a flash',
      'Bounce up to four balls at once. Do you have the skillz?',
      'Unlock and upgrade new bumpers. Turn them from wimpy sidekick to powerful partner',
      'Enchant the eyes with colorful, comic book vibes',
    ],
    postDescription: [
      'The universe needs you. Play into infinity with endless upgrades.',
    ],
    googlePlayLink:
      'https://play.google.com/store/apps/details?id=com.actop.bounce',
    appStoreLink:
      'https://itunes.apple.com/us/app/bounce-of-legends/id1370235598?ls=1&mt=8',
  },
  {
    title: 'Double Chips',
    videoLink: 'https://youtu.be/sWNZmKaW7qs',
    icon: doubleChipsIcon,
    image: { url: doubleChipsImg, orientation: 'portrait' },
    description: [
      'Swipe chips with the same number together to make them into a new, better chip. What’s the highest chip you can get?',
    ],
    features: [
      'Merge matching chips to level them up',
      'Reach chip goals',
      'Cash in to get coins',
      'Restart when you are stuck',
      'Use power-ups to go further',
      'Five rooms full of puzzles to unlock',
      'Advance through new tables and puzzle designs',
      'Use power-ups to amp up your gameplay',
      'Win prizes',
    ],
    postDescription: [],
    googlePlayLink:
      'https://play.google.com/store/apps/details?id=com.actop.doublechips',
    appStoreLink:
      'https://itunes.apple.com/us/app/double-chips/id1245396073?mt=8',
  },
  {
    title: 'Werewolf Run',
    videoLink: 'https://youtu.be/osF0qdJ7ku0',
    icon: werewolfRunIcon,
    image: { url: werewolfRunImg, orientation: 'landscape' },
    description: ['Rule the night in this addictive one tap endless runner!'],
    features: [
      'Simple one tap gameplay',
      'Easy to learn, hard to master',
      'Exciting character customizations',
      'Deadly traps',
      'Sneaky hunters',
      'Dark and beautiful graphics',
      'Gloomy, magical vibe',
      'Suspenseful techno background music',
    ],
    postDescription: [],
    googlePlayLink:
      'https://play.google.com/store/apps/details?id=com.actop.werewolfrun',
    appStoreLink:
      'https://itunes.apple.com/us/app/werewolf-run/id1297500618?mt=8',
  },
  {
    title: 'Croquet Clash',
    videoLink: 'https://youtu.be/w5XO1SulBjI',
    icon: croquetClashIcon,
    image: { url: croquetClashImg, orientation: 'landscape' },
    description: [
      'Play explosive real-time multiplayer croquet right from your phone!',
    ],
    features: [
      'Free to play! No ads!',
      'Play multiplayer with your friends',
      'Compete 1 on 1 or in groups',
      'Use special attacks. Leave mines, boot your competitors out of the way, reverse their direction, or SMASH them into the ground!',
      'Practice on your own. Increase your skills',
      'Compete against your friends or be matched to players around the world',
      'Realtime multiplayer croquet fun',
      'Upgrade your mallets and balls',
      'Level up to gain access to more exclusive mallets and balls',
      'Fun 3d graphics',
    ],
    postDescription: [
      'COMPETE 1 ON 1',
      'Play a quick game against a friend or another member of the community. Timed matches keep things short and fun.',
      'PLAY IN A GROUP',
      'Play with up to four people in a game. Invite your friends or be matched to multiple people around the world.',
      'CREATE YOUR PRIVATE ACCOUNT',
      'Connect up with Facebook or through email.',
      'CHALLENGE YOUR FRIENDS',
      'Add your friends based on their user id, email, or through Facebook.',
      'PLAY WITH PEOPLE FROM AROUND THE WORLD',
      'Match automatically to other players around the world to compete in real time.',
      'SIX DIFFERENT COURTS TO CHOOSE FROM',
      'Play your croquet game on the official court, in the backyard, at the ranch, in the city, on the beach, or even on the moon!',
      'FREE TO PLAY',
      'Croquet Clash is free to download and play with no interstitial ads! Some game items may be purchased with real money.',
    ],
    googlePlayLink:
      'https://play.google.com/store/apps/details?id=com.actop.croquet',
    appStoreLink:
      'https://itunes.apple.com/us/app/croquet-clash/id1223055124?mt=8',
  },
  {
    title: 'Mining Mountain',
    videoLink: 'miningMountainImg',
    icon: miningMountainIcon,
    image: { url: miningMountainImg, orientation: 'portrait' },
    description: [
      'Become the Ultimate Mining Overlord! Grandpa is counting on you!',
    ],
    features: [
      'Tap to mine gold!',
      'Hire hard working miners',
      'Buy big, powerful tractors',
      'Invest in upgrades',
      'Collect deliveries',
      'Mine mysterious new locations on Earth and in Space!',
    ],
    postDescription: [],
    googlePlayLink: 'https://goo.gl/2lNmTW',
    appStoreLink: 'https://goo.gl/FjBocX',
  },
  {
    title: 'NoJo',
    videoLink: '',
    icon: nojoIcon,
    image: { url: nojoImg, orientation: 'portrait' },
    description: [
      'Say goodbye to boredom! NoJo is a fun, addicting, and simple bouncy ball game. Tap your finger to keep one, two, three, four… balls in the air. Juggle as many bouncy balls as you can to rack up points. NoJo features one-touch gameplay which makes it perfect for playing while you wait in line, watch TV, or chill on the couch. This is casual gaming at its finest. With its retro vibe and dual-colored graphics NoJo is fun for every type of gamer.',
    ],
    features: [
      'The controls are easy to learn, just tap the bottom of the balls',
      'Difficulty grows as balls continue to drop',
      'Compete with your friends and earn achievements',
      'Hit the balls and get competitive',
      'Aesthetically pleasing simplicity and retro font',
      'Be happy! It’s free to play!',
    ],
    postDescription: [],
    googlePlayLink: '',
    appStoreLink: '',
  },
  {
    title: 'Metrigon',
    videoLink: '',
    icon: metrigonIcon,
    image: { url: metrigonImg, orientation: 'portrait' },
    description: [
      'Flex your genius with this exciting new strategy game. Exercise your brain by filling in the empty spaces.',
    ],
    features: [
      'Use your critical thinking and spatial awareness skills to earn top scores',
      'Beginning with a single shape, copy, fuse, and rotate polygons in order to fill in the grid in as few moves as possible',
      'Puzzle collections feature themes such as cats, aliens, yummy food, and more',
      'Connect up with Facebook to see how your total score compares with your friends',
    ],
    postDescription: [],
    googlePlayLink: 'https://goo.gl/2lNmTW',
    appStoreLink: 'https://goo.gl/FjBocX',
  },
];

export default gameList;
