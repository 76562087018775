import styled from 'styled-components';
import { device } from '../../common/styles/device';
import { NavLink } from 'react-router-dom';

const StyledLegal = styled.section`
  padding-top: 1rem;
`;

const LegalMenu = styled.div`
  margin: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media ${device.large} {
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
`;

const MenuLink = styled(NavLink)`
  margin: 0 1rem;

  &.active-link {
    color: black;
  }
  &:hover {
    text-decoration: underline;
  }
`;

const PageTitle = styled.h1`
  margin-top: 1rem;
  text-align: center;
`;

export { LegalMenu, MenuLink, PageTitle };
export default StyledLegal;
