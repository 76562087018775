import React from 'react';
import { Route, Switch } from 'react-router-dom';
import StyledLegal, { LegalMenu, MenuLink, PageTitle } from './Legal.styles';
import ShowDoc from './ShowDoc';
// import privacyPolicyjs from '../../assets/docs/privacyPolicy';
// import termsOfServicejs from '../../assets/docs/termsOfService';
import privacyPolicyPath from '../../assets/docs/actOpPrivacyPolicy.txt';
import termsOfServicePath from '../../assets/docs/actOpTermsofService.txt';

const Legal = () => {
  return (
    <StyledLegal>
      <PageTitle>Legal Information</PageTitle>
      <LegalMenu>
        <MenuLink to='/legal/privacy-policy' activeClassName='active-link'>
          Privacy Policy
        </MenuLink>
        <MenuLink to='/legal/terms-of-service' activeClassName='active-link'>
          Terms of Service
        </MenuLink>
      </LegalMenu>
      <Switch>
        <Route path='/legal/privacy-policy'>
          <ShowDoc textDocPath={privacyPolicyPath} />
        </Route>
        <Route path='/legal/terms-of-service'>
          <ShowDoc textDocPath={termsOfServicePath} />
        </Route>
      </Switch>
    </StyledLegal>
  );
};

export default Legal;
