import React, { useState, useCallback } from 'react';
import styled, { css } from 'styled-components';
import { Axios, db } from '../../services/firebaseConfig';
import { Link } from 'react-router-dom';
import Recaptcha, { RECAPTCHA_STATES } from './Recaptcha';

const submitStates = {
  active: 'active',
  submitting: 'submitting',
  submitted: 'submitted',
  error: 'error',
};

const ContactForm = () => {
  const [formData, setFormData] = useState({});
  const [submitState, setSubmitState] = useState(submitStates.active);
  const [recaptchaState, setRecaptchaState] = useState(
    RECAPTCHA_STATES.waiting
  );

  const updateInput = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (recaptchaState === RECAPTCHA_STATES.verified) {
      sendEmail();
      setFormData({
        name: '',
        email: '',
        message: '',
      });
    } else if (recaptchaState === RECAPTCHA_STATES.waiting) {
      setRecaptchaState(RECAPTCHA_STATES.actionRequested);
    }
  };

  const recaptchaResultCallback = useCallback((result) => {
    setRecaptchaState(result);
  }, []);

  const sendEmail = () => {
    setSubmitState(submitStates.submitting);
    Axios.post(
      'https://us-central1-actop-ad243.cloudfunctions.net/submit',
      formData
    )
      .then((res) => {
        db.collection('emails').add({
          name: formData.name,
          email: formData.email,
          message: formData.message,
          time: new Date(),
        });
        setSubmitState(submitStates.submitted);
      })
      .catch((error) => {
        setSubmitState(submitStates.error);
        console.log(error);
      });
  };

  const message = (() => {
    switch (submitState) {
      case submitStates.submitting:
        return 'Submitting';
      case submitStates.submitted:
        return 'Thank you for your feedback!';
      case submitStates.error:
        return 'Oops, some sort of error happened in submitting your feedback.';
      default:
        return null;
    }
  })();

  return (
    <FormArea>
      <Form onSubmit={handleSubmit}>
        {message && (
          <MessageBox>
            <Message>{message}</Message>
            <HomeLink to='/'>Dismiss</HomeLink>
          </MessageBox>
        )}
        <Title>Contact Us</Title>
        <Label htmlFor='name'>Name</Label>
        <Input
          type='text'
          name='name'
          placeholder=''
          required='required'
          onChange={updateInput}
          value={formData.name || ''}
        />
        <Label htmlFor='email'>Email</Label>
        <Input
          type='email'
          name='email'
          placeholder=''
          required='required'
          onChange={updateInput}
          value={formData.email || ''}
        />
        <Label htmlFor='message'>Message</Label>
        <TextArea
          type='text'
          name='message'
          placeholder=''
          required='required'
          onChange={updateInput}
          value={formData.message || ''}
        ></TextArea>
        <Recaptcha
          resultCallback={recaptchaResultCallback}
          recaptchaState={recaptchaState}
        />
        <Button type='submit' disabled={submitState !== submitStates.active}>
          Submit
        </Button>
      </Form>
    </FormArea>
  );
};

export default ContactForm;

const FormArea = styled.div`
  background-color: white;
`;

const Label = styled.label`
  color: rgb(51, 51, 51);
  font-weight: 400;
  &::after {
    color: red;
    content: ' *';
  }
`;

const Form = styled.form`
  display: flex;
  position: relative;
  flex-direction: column;
  width: 90%;
  max-width: 30rem;
  margin: 2rem auto;
  /* margin-top: 1rem; */
  background-color: white;
  border-radius: 1rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 1rem;
`;

const Title = styled.h1`
  color: black;
  font-weight: 800;
  font-size: 2rem;
  text-align: center;
`;

const inputStyles = css`
  font: 18px Helvetica, Arial, sans-serif;
  box-sizing: border-box;
  height: 2rem;
  /* display: block; */
  border-radius: 3px;
  vertical-align: middle;
  border: 1px solid #ddd;
  box-shadow: none;
  padding: 2px 1rem;
  margin-bottom: 1rem;
  color: #555;
  font-size: 18px;
  transition: all 0.2s ease-in-out;
  &:focus-visible {
    outline: none;
  }
`;

const TextArea = styled.textarea`
  ${inputStyles}
  height: 4rem;
  padding: 0.5rem 1rem;
  resize: none;
`;

const Input = styled.input(inputStyles);

const Button = styled.button`
  transition: all 0.2s ease-in-out;
  font: 18px Helvetica, Arial, sans-serif;
  border: none;
  background: #1792e8;
  color: #fff;
  padding: 10px;
  width: 50%;
  text-transform: uppercase;
  letter-spacing: 5px;
  margin: 1rem auto;
  border-radius: 1rem;
  margin-bottom: 0.5rem;

  &:hover {
    background: #0e82d4;
    cursor: pointer;
  }
  &:disabled {
    background: gray;
  }
`;

const MessageBox = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 80%;
  height: 50%;
  max-width: 20rem;
  max-height: 10rem;
  color: black;
  background-color: white;
  border-radius: 1rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
`;

const Message = styled.div`
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);

  text-align: center;
`;

const HomeLink = styled(Link)`
  position: absolute;
  top: 80%;
  left: 50%;
  transform: translate(-50%, -50%);

  color: #1ca3c4;

  &:hover {
    color: #1347d1;
    cursor: pointer;
  }
`;
