import React from 'react';
import styled from 'styled-components';
import gameList from './gameList';
import Game from './Game';

const title = 'A big thank you to all of our customers!';
const message = `We had a great time developing games and interacting with all of our wonderful customers. \
                 Unfortunately ActOp's games are no longer available for download.  We were a small family based indie \
                 game developer and weren't able to find the right formula for long term success.  I had a great time \
                 working with my team and especially with my talented children who have moved on to successful careers using their respective \
                 talents.  As for me, I'm an entrepreneur working on the next thing. See you soon.`;
const date = 'November 20, 2020';
const signature = 'Joe Romriell';
const singatureTitle = 'Founder and CEO';

const Games = () => {
  return (
    <StyledGames>
      <Message>
        <MessageTitle>{title}</MessageTitle>
        <Date>{date}</Date>
        <MessageContent>{message}</MessageContent>
        <SingatureArea>
          <Signature>{signature}</Signature>
          <SignatureTitle>{singatureTitle}</SignatureTitle>
        </SingatureArea>
      </Message>
      <GamesTitle>Games</GamesTitle>
      {gameList.map((gameInfo, i) => (
        <Game key={gameInfo.title} gameInfo={gameInfo} listNum={i} />
      ))}
    </StyledGames>
  );
};

const Message = styled.div`
  margin: 2rem 2rem 2rem 2rem;
  /* padding-top: 2rem; */
`;
const SingatureArea = styled.div`
  /* width: 100%; */
  display: flex;
  flex-direction: column;
  text-align: right;
  margin-right: 1rem;
`;
const Date = styled.div``;
const Signature = styled.div``;
const SignatureTitle = styled.div``;
const MessageTitle = styled.h2``;
const MessageContent = styled.p`
  margin: 1rem 0;
`;
const StyledGames = styled.section`
  padding-bottom: 5rem;
`;
const GamesTitle = styled.h1`
  padding-top: 1rem;
  text-align: center;
  font-size: 2rem;
`;

export default Games;
