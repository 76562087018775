import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import logoImage from '../../assets/images/ActOpFullSizeCutout.png';

const MENU_LINKS = [
  // { location: '/games', text: 'GAMES' },
  // { location: '/news', text: 'NEWS' },
  { location: '/contact', text: 'CONTACT US' },
];

const Navbar = () => {
  const location = useLocation();
  const pathname = location.pathname;
  return (
    <StyledNavbar>
      <HomeLink to='/'>
        <Logo src={logoImage} />
      </HomeLink>
      <MenuItems>
        {MENU_LINKS.map((menuItem) => (
          <MenuItem key={menuItem.location}>
            <MenuLink
              to={menuItem.location}
              selected={menuItem.location === pathname}
            >
              {menuItem.text}
            </MenuLink>
          </MenuItem>
        ))}
      </MenuItems>
    </StyledNavbar>
  );
};

export default Navbar;

const Logo = styled.img`
  width: 10rem;
`;

const MenuItems = styled.ul`
  margin: auto;
`;

const MenuItem = styled.li``;

const MenuLink = styled(Link)`
  display: flex;
  color: ${(props) =>
    props.selected ? props.theme.colors.menuActive : props.theme.colors.menu};
  padding: 0 0;
  margin: 0 1rem;
  text-align: center;
  ${(props) =>
    props.selected
      ? `border-bottom: 1px solid ${props.theme.colors.menuActive};`
      : null};

  &:hover {
    color: ${(props) => props.theme.colors.menuHover};
    border-bottom: 1px solid ${(props) => props.theme.colors.menuHover};
  }
`;

const StyledNavbar = styled.nav`
  display: flex;
  position: fixed;
  height: ${(props) => props.theme.navbarHeight};
  justify-content: space-between;
  align-items: center;
  padding: 0.7rem 2rem 0.7rem 1.25rem;
  z-index: 100;
  width: 100%;
  top: 0;
  background-color: ${(props) => props.theme.colors.navbar};
  box-shadow: 0px 3px 3px gray;

  ul {
    display: flex;
    align-items: center;
    list-style: none;
  }
`;

const HomeLink = styled(Link)`
  color: ${(props) => props.theme.colors.logoText};

  &:hover {
    color: ${(props) => props.theme.colors.logoHover};
  }
`;
