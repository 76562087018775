import React from 'react';
import styled from 'styled-components';
import useWindowDimensions, { device } from '../../common/styles/device';

const Game = ({ gameInfo, listNum }) => {
  const imgPortrait = gameInfo.image.orientation === 'portrait';
  const { isMobile, isLarge } = useWindowDimensions();
  const reverse = listNum % 2 !== 0 && imgPortrait && isLarge;
  return (
    <StyledGame>
      <GameDescription reverse={reverse} imgPortrait={imgPortrait}>
        <TextArea reverse={reverse}>
          <GameTitle>
            <GameIcon src={gameInfo.icon} />
            <Title>{gameInfo.title}</Title>
          </GameTitle>
          {(isMobile || !imgPortrait) && (
            <GameImg src={gameInfo.image.url} imgPortrait={imgPortrait} />
          )}
          {gameInfo.description.map((p, i) => (
            <Description key={i}>{p}</Description>
          ))}

          <GameFeatures>
            {gameInfo.features.map((feature, i) => (
              <GameFeature key={i}>{feature}</GameFeature>
            ))}
          </GameFeatures>
          {gameInfo.postDescription.map((p, i) => (
            <PostDescription key={i}>{p}</PostDescription>
          ))}
        </TextArea>
        {isLarge && imgPortrait && (
          <GameImg src={gameInfo.image.url} imgPortrait={imgPortrait} />
        )}
      </GameDescription>
    </StyledGame>
  );
};

export default Game;

const StyledGame = styled.div`
  margin: 1rem 2rem 2rem 2rem;
`;
const GameDescription = styled.div`
  margin: 1rem 0;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  @media ${device.large} {
    ${(props) =>
      props.reverse
        ? 'flex-direction: row-reverse;'
        : 'flex-direction: row; justify-content: flex-start'}
  }
`;

const TextArea = styled.div`
  @media ${device.large} {
    ${(props) => (props.reverse ? 'margin-left: 4rem;' : 'margin-right: 4rem')}
  }
`;
const GameTitle = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
`;
const Title = styled.h2``;
const GameIcon = styled.img`
  width: 4rem;
  height: 4rem;
  ${(props) => (props.reverse ? 'margin-left: 1rem;' : 'margin-right: 1rem')}
`;
const Description = styled.p`
  margin-bottom: 1rem;
`;
const GameFeatures = styled.ul`
  margin-left: 1rem;
  /* list-style: inside; */
`;
const GameFeature = styled.li``;
const PostDescription = styled.p`
  margin-top: 1rem;
`;
const GameImg = styled.img`
  width: 100%;
  ${(props) =>
    props.imgPortrait
      ? ' max-width: 20rem; margin: 1rem auto;'
      : ' max-width: 40rem; margin: 1rem auto;'}
  @media ${device.large} {
    ${(props) =>
      props.imgPortrait
        ? 'width: auto; height: 25rem; margin: 5.5rem 0 0;'
        : 'width: 25rem; margin: 0;'}
  }
`;
