import React, { useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { Axios, firebaseConfig } from '../../services/firebaseConfig';

const recaptchaAction = 'submit';
const scoreVerifiedMin = 0.4;

const recaptchaRequest = `https://recaptchaenterprise.googleapis.com/v1beta1/projects/${firebaseConfig.projectId}/assessments?key=${firebaseConfig.apiKey}`;
export const RECAPTCHA_STATES = {
  waiting: 'waiting',
  actionRequested: 'Please verify that you are not a robot.',
  unverified: 'Unable to verify that you are not a robot.',
  verified: 'verified',
  expired: 'Please refresh and try again.',
  error: 'error',
};

const Recaptcha = ({ recaptchaState, resultCallback }) => {
  const showMessage =
    recaptchaState === RECAPTCHA_STATES.actionRequested ||
    recaptchaState === RECAPTCHA_STATES.unverified;

  const reCaptchaCallback = useCallback(
    (token) => {
      const recaptchaEvent = {
        event: {
          token: token,
          siteKey: firebaseConfig.recaptcha_sitekey,
          expectedAction: recaptchaAction,
        },
      };

      Axios.post(recaptchaRequest, recaptchaEvent)
        .then((res) => {
          resultCallback(
            res.data.score >= scoreVerifiedMin
              ? RECAPTCHA_STATES.verified
              : RECAPTCHA_STATES.unverified
          );
        })
        .catch((error) => {
          resultCallback(RECAPTCHA_STATES.error);
          console.log('Error from recaptcha: ', error);
        });
    },
    [resultCallback]
  );

  const recaptchaExpiredCallback = useCallback(() => {
    resultCallback(RECAPTCHA_STATES.expired);
  }, [resultCallback]);

  useEffect(() => {
    window.reCaptchaCallback = reCaptchaCallback;
    window.recaptchaExpiredCallback = recaptchaExpiredCallback;
    const scriptTag = document.createElement('script');

    scriptTag.src = 'https://www.google.com/recaptcha/enterprise.js';
    scriptTag.async = true;
    scriptTag.defer = true;
    document.body.appendChild(scriptTag);
    return () => {
      document.body.removeChild(scriptTag);
    };
  }, [reCaptchaCallback, recaptchaExpiredCallback]);

  return (
    <StyledRecaptcha showMessage={showMessage}>
      <RecaptchaBox
        className='g-recaptcha'
        data-sitekey={firebaseConfig.recaptcha_sitekey}
        data-callback='reCaptchaCallback'
        data-action={recaptchaAction}
        data-expired-callback='recaptchaExpiredCallback'
      ></RecaptchaBox>
      {showMessage && <UserMessage>{recaptchaState}</UserMessage>}
    </StyledRecaptcha>
  );
};

export default Recaptcha;

const RecaptchaBox = styled.div``;
const StyledRecaptcha = styled.div`
  ${(props) =>
    props.showMessage ? 'border: 1px solid #dd4b39; padding: 5px;' : ''};
`;

const UserMessage = styled.div`
  color: #dd4b39;
  font-size: 12px;
  padding: 4px 0;
`;
