// Firebase App (the core Firebase SDK) is always required and must be listed first

import firebase from 'firebase/app';
import 'firebase/analytics';
// import 'firebase/auth';
import 'firebase/firestore';
import Axios from 'axios';

const firebaseConfig = {
  apiKey: 'AIzaSyBvXaNimitqI_1GzBYrx57DesKeioOlNuc',
  authDomain: 'actop-ad243.firebaseapp.com',
  projectId: 'actop-ad243',
  storageBucket: 'actop-ad243.appspot.com',
  messagingSenderId: '21694027023',
  appId: '1:21694027023:web:d58984370dac13d503da7a',
  measurementId: 'G-4PV4M0K7ME',
  recaptcha_sitekey: '6LdM2sMbAAAAAHaFHut6CJdttGnYY6KXIaQyUVbM',
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

const db = firebase.firestore();

export { Axios, db, firebaseConfig };
