import React, { Fragment } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Theme from './common/styles/Theme';
import { GlobalStyle, Content, Page } from './App.styles.js';
import Navbar from './components/layout/Navbar';
import Footer from './components/layout/Footer';
import Games from './components/games/Games';
import ContactForm from './components/contact/ContactForm';
import Legal from './components/Legal/Legal';
import NotFound from './components/layout/NotFound';

import './App.css';

function App() {
  return (
    <Fragment>
      <Theme>
        <GlobalStyle />
        <Router>
          <Page>
            <Navbar />
            <Content>
              <Switch>
                <Route exact path='/' component={Games} />
                <Route exact path='/games' component={Games} />
                {/* <Route exact path='/news' component={News} /> */}
                <Route exact path='/contact' component={ContactForm} />
                <Route path='/legal' component={Legal} />
                <Route component={NotFound} />
              </Switch>
            </Content>
            <Footer />
          </Page>
        </Router>
      </Theme>
    </Fragment>
  );
}

export default App;
