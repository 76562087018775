import styled, { createGlobalStyle } from 'styled-components';

// GlobalStyle simply creates something like an App.css with these styles.

const GlobalStyle = createGlobalStyle`

  /* The plan isn't to use variables this way.    */
  :root {
  --primary-color: #17a2b8;
  --dark-color: #343a40;
  --light-color: #f4f4f4;
  --danger-color: #dc3545;
  --success-color: #28a745;
}

  * {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  }  

  body {
    margin: 0;
    /* font-family: ${(props) => props.theme.fontFamily}; */
    font-family: 'Roboto', sans-serif;
    font-size: 1rem;
    line-height: 1.6;
    background-color: ${(props) => props.theme.colors.background};
    color: #333;
    height: 100vh;

    /* Example ideas:
    font-family: 'Roboto';
  max-width: 100vw;
   max-width: 100vw;
    height: 100vh;
    overflow-y: hidden;
    margin: 0;
    padding: 0; */
  }

  a {
  color: var(--primary-color);
  text-decoration: none;
}

`;

const Page = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const Content = styled.div`
  /* max-width: 1100px; */
  /* min-height: 100vh; */
  flex-grow: 1;
  overflow: visible;

  /* padding: 0 2rem; */
  padding-top: ${(props) => props.theme.navbarHeight};
  /* margin-bottom: 3rem; */
  /* padding-bottom: ${(props) => props.theme.navbarHeight}; ; */
`;

export { GlobalStyle, Content, Page };
