import React, { Fragment, useEffect, useState } from 'react';
import styled from 'styled-components';

const getTxtDoc = (txtPath, callBack) => {
  fetch(txtPath)
    .then((response) => response.text())
    .then((data) => {
      callBack(data);
    });
};

const ShowDoc = ({ textDocPath }) => {
  const [textDoc, setTextDoc] = useState(null);
  useEffect(() => {
    if (textDocPath !== null) {
      getTxtDoc(textDocPath, setTextDoc);
      window.scrollTo(0, 0);
    }
  }, [textDoc, textDocPath, setTextDoc]);

  if (textDoc === null) {
    return null;
  }

  // var paragraphs = textDoc.body.match(/[^\r\n]+/g);
  var paragraphs = textDoc.match(/[^\r\n]+/g);

  var docParagraphs = paragraphs.map((par, i) => {
    return <DocP key={i}>{par}</DocP>;
  });

  return (
    <Fragment>
      <DocView>
        <DocTitle>{textDoc.title}</DocTitle>
        <DocBody>{docParagraphs}</DocBody>
      </DocView>
    </Fragment>
  );
};

const DocView = styled.div`
  margin: auto;
  padding: 1rem;
  max-width: 640px;
`;

const DocTitle = styled.h2`
  text-align: center;
  margin-bottom: 1rem;
`;

const DocBody = styled.section`
  text-align: center;
`;

const DocP = styled.p`
  text-align: left;
  margin-bottom: 1rem;
`;
export default ShowDoc;
